import request from '@/utils/request'

const api = {
  gameFocus: '/api/game/focus', // 添加关注
  gameUnfocus: '/api/game/unfocus', // 取消关注
  gameFocusOrder: '/api/game/focusorder', //关注排序
  gameFocusList: '/api/game/focuslist'
}

export function gameFocus(parameter) {
  return request({
    url: api.gameFocus,
    method: 'get',
    params: parameter
  })
}

export function gameUnfocus(parameter) {
  return request({
    url: api.gameUnfocus,
    method: 'get',
    params: parameter
  })
}

export function gameFocusOrder(parameter) {
  return request({
    url: api.gameFocusOrder,
    method: 'get',
    params: parameter
  })
}

export function gameFocusList(parameter) {
  return request({
    url: api.gameFocusList,
    method: 'get',
    params: parameter
  })
}



