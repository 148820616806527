import axios from 'axios'
import store from '@/store'
import storage from 'store'
import { VueAxios } from './axios'
import config from '../config/config'
import { ACCESS_TOKEN } from '@/store/mutation-types'

// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀 +  接口请求增加版本号
  baseURL: process.env.VUE_APP_API_BASE_URL + '/' + config.vesion,
  timeout: 30000, // 请求超时时间
  withCredentials: true,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
  }
})

console.log(store, config)

// 异常拦截处理器
const errorHandler = (error) => {
  if (error.response) {
    const data = error.response.data
    // 从 localstorage 获取 token
    const token = storage.get(ACCESS_TOKEN)
    console.log(token)
    if (error.response.status === 403) {
      console.log('403')
    }
    if (error.response.status === 401 && !(data.result)) {
      console.log('Unauthorized')
    }
  }
  return Promise.reject(error)
}

// request interceptor
request.interceptors.request.use(res => {
  // 取消token验证，使用cookie登录验证
  const token = storage.get(ACCESS_TOKEN)
  // 如果 token 存在
  // 让每个请求携带自定义 token 请根据实际情况自行修改
  if (token) {
    res.headers['Access-Token'] = token
  }
  return res
}, errorHandler)

// response interceptor
request.interceptors.response.use((response) => {
  console.log(response)
  return response.data
}, errorHandler)

const installer = {
  vm: {},
  install(Vue) {
    Vue.use(VueAxios, request)
  }
}

export default request

export {
  installer as VueAxios,
  request as axios
}
